<template>
  <div class="checkbox-wrapper" :class="{ checked: value }">
    <input
      :id="'cbx_id_' + id"
      v-model.trim="currentValue"
      type="checkbox"
      class="checkbox"
      :name="name"
      :value="value"
      :readonly="readonly"
      :disabled="disabled"
      @change="changeValue"
    >
    <label class="checkbox-label" :for="'cbx_id_' + id">
      <slot />
    </label>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  props: {
    id: {
      type: String,
      default: 'defaultId',
      require: true
    },
    name: {
      type: String,
      default: 'defaultName'
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentValue: false
    };
  },
  watch: {
    value (newVal) {
      this.currentValue = newVal;
      // //console.log( this.currentValue)
    }
  },
  mounted () {
    this.currentValue = this.value;
  },
  methods: {
    changeValue () {
      this.$emit('input', this.currentValue);
      this.$emit('change', this.currentValue);
      let params = {
        value: this.currentValue,
        id: this.id
      };
      this.$emit('select', params);
    }
  }
};
</script>
<style lang="stylus" rel="stylesheet/stylus">
.checkbox-wrapper + .checkbox-wrapper
    margin-left 30px
.checkbox-wrapper
    display inline-block
    .checkbox-label
        padding-left 24px
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        width 100%
        display inline-block
    .checkbox
        display none
        & + label
            padding-left 24px
            line-height 22px
            height 22px
            font-size 14px
            vertical-align middle
            cursor pointer
            // color $txtNormal
            color #21233c
            background url('./select_default.png') no-repeat left center / 14px
            background-position-y center
            &:hover
                background url('./select_hover.png') no-repeat left center / 14px
        &:checked + label
            background url('./select_selected.png') no-repeat left center / 14px
        &:checked[half-checked] + label
            background url('./select_half_selected.png') no-repeat left center / 14px
    .checkbox[disabled]
        & + label
            background url('./select_default_disabled.png') no-repeat left center / 14px
        &:checked + label
            background url('./select_selected_diasbled.png') no-repeat left center / 14px
        &:checked.default-check + label
            background url('./select_default_checked_select.png') no-repeat center / 14px
        &:checked[half-checked] + label
            background url('./select_half_disabled.png') no-repeat left center / 14px
</style>
